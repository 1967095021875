import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, FormikHelpers } from "formik";
import { Redirect, Link } from "react-router-dom";
import * as _ from "lodash";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import {
  UserSignupFormValues,
  Auth,
  SubscriptionsAppState,
  Subscriptions,
  Invitation,
  ReferralEntity
} from "@ctra/api";

import { Auth as AuthUI, Grid, Typography, Row, Col, Button, MailFilled, Divider } from "@ctra/components";
import { useTranslation, Subscriptions as Content, parseLocoTemplate } from "@ctra/i18n";
import {
  isFulfilled,
  isLocal,
  isProduction,
  Nullable,
  CookieKeys,
  Cookies,
  Debug,
  isIosDevice,
  isBlueGreen
} from "@ctra/utils";

import { LanguageSelector } from "@base";
import { Routes } from "@routes";

import { GACategories } from "../../analytics";
import { SignupForm } from "../SignupForm";
import { useValidationSchema } from "./hooks";
import styles from "./SignupPage.module.less";

const { Text } = Typography;
const { AuthPage, WidgetWrapper, SSOList } = AuthUI;

declare global {
  interface Window {
    CTRA_MOBILE_WRAPPER: boolean;
    CTRA_DEVICE_INFO: Record<string, unknown>;
    Median: any;
  }
}

/**
 * Sign up page component with form
 * @constructor
 */
const SignupPage: FC = () => {
  const [formEmail, setFormEmail] = useState<string>();
  /* @ts-ignore */
  const [isFormActive, setIsFormActive] = useState<boolean>(isIosDevice());
  const { t } = useTranslation();
  const validationSchema = useValidationSchema();
  const dispatch = useDispatch();
  const { md } = Grid.useBreakpoint();
  const invitationCode = Cookies.get(CookieKeys.invitationCode);

  /**
   * Redirect domain
   * @type {string}
   */
  const redirectDomain = isLocal()
    ? "localhost"
    : isBlueGreen()
    ? "deploy-app.connecterra.ai"
    : `${isProduction() ? "" : "staging-"}app.connecterra.ai`;

  /**
   * Login text and link
   */
  const [loginText, loginLink] = _.defaultTo(parseLocoTemplate(t<string>(Content.signup.form.footer)), []);

  /**
   * Get the invitation which matches the invitation code
   */
  const [, invitation] = useSelector<SubscriptionsAppState, [boolean, Nullable<ReferralEntity>]>((state) =>
    isFulfilled(state, Invitation.types.FETCH_INVITATION_BY_CODE, {
      primaryValue: _.defaultTo(invitationCode, -1),
      withPayload: true
    })
  );

  /**
   * Tell whether the user has signed up
   * @type {boolean}
   */
  const hasSignedUp = useSelector<SubscriptionsAppState, boolean>((state) =>
    isFulfilled(state, Auth.types.SIGN_UP_USER)
  );

  /**
   * Tell whether the user is logged in
   * @type {boolean}
   */
  const isLoggedIn = useSelector<SubscriptionsAppState, boolean>(Subscriptions.entities.isLoggedIn);

  /**
   * Fetch invitations if there is a code in the params
   */
  useEffect(() => {
    if (invitationCode) {
      dispatch(Invitation.actions.fetchInvitationByCode.start(invitationCode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationCode]);

  /**
   * Redirect after sign up
   */
  useEffect(() => {
    if (hasSignedUp && formEmail) {
      const searchParams = new URLSearchParams();
      searchParams.set("email", formEmail);

      Subscriptions.history.push({
        pathname: Routes.signup.signupSuccess,
        search: searchParams.toString()
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSignedUp, formEmail]);

  /**
   * Submit form
   * @param values
   * @param setSubmitting
   */
  const onSubmit = (
    values: UserSignupFormValues,
    { setSubmitting }: FormikHelpers<UserSignupFormValues>
  ): void => {
    const { email, password, confirmPassword, firstName, lastName, eulaAccepted, phoneNumber, ...rest } =
      values;

    setFormEmail(email);

    /**
     * When you click on signup, your should be referred back
     * to the signup app after the user is created
     */
    Cookies.set(CookieKeys.referrer, "signup");

    dispatch(
      Auth.actions.signupUser.start({
        email: _.toLower(email),
        password,
        confirmPassword,
        firstName,
        lastName,
        eulaAccepted,
        phoneNumber,
        "g-recaptcha-response": rest["g-recaptcha-response"]
      })
    );

    setSubmitting(false);
  };

  return isLoggedIn ? (
    <Redirect to={Routes.app.gettingStarted.selectRole.index} />
  ) : (
    <GoogleReCaptchaProvider reCaptchaKey="6LddPsMpAAAAAO0uuxY_-mcHctMonlwr28FO_bV3">
      <AuthPage header={<LanguageSelector />}>
        <WidgetWrapper
          className={styles.WidgetWrapper}
          introTitle={t<string>(Content.signup.pageTitle)}
          title={t<string>(Content.signup.form.title)}
          footer={
            <>
              <Text>{loginText}</Text>
              {md ? " " : <br />}
              <Text strong>
                <Link
                  data-gtm-category={GACategories.signup}
                  data-gtm-action="Navigate back to the login page"
                  to={Routes.auth.login}
                >
                  {loginLink}
                </Link>
              </Text>
            </>
          }
        >
          {isFormActive ? (
            <Formik
              initialValues={{
                email: _.get(_.defaultTo(invitation, {}), "email", ""),
                firstName: _.get(_.defaultTo(invitation, {}), "firstName", ""),
                lastName: _.get(_.defaultTo(invitation, {}), "lastName", ""),
                eulaAccepted: true,
                "g-recaptcha-response": ""
              }}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {(formikProps) => <SignupForm {...formikProps} />}
            </Formik>
          ) : (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Button
                  data-gtm-category={GACategories.signup}
                  data-gtm-action="Sign up with email"
                  icon={<MailFilled />}
                  size="large"
                  block
                  onClick={() => setIsFormActive(true)}
                >
                  {t<string>(Content.signup.social, { provider: "email" })}
                </Button>
              </Col>
            </Row>
          )}
          {isFormActive || isIosDevice() ? null : (
            <>
              <Divider>or</Divider>
              <SSOList
                getLabel={(provider) =>
                  t<string>(Content.signup.social, { provider: _.upperFirst(provider) })
                }
                onClick={(provider) => {
                  const params = new URLSearchParams();

                  /**
                   * If you click on the SSO from the signup page, then we
                   * assume your intention is to sign up, so the signup app
                   * should follow
                   */
                  Cookies.set(CookieKeys.referrer, "signup");

                  // @ts-ignore
                  params.set("redirectDomain", window.CTRA_MOBILE_WRAPPER ? "mobile" : redirectDomain);
                  params.set("provider", _.upperFirst(provider));

                  const url = `https://auth${
                    isProduction() ? "" : ".staging"
                  }.connecterra.ai/sso/login?${params.toString()}`;

                  Debug.authModule.info("SSO signup link clicked", url);

                  // @ts-ignore
                  if (window.CTRA_MOBILE_WRAPPER && _.has(window, "Median")) {
                    // @ts-ignore
                    Median.window.open(url, "external");
                  } else if (window) {
                    window.open(url, "_self");
                  }
                }}
              />
            </>
          )}
        </WidgetWrapper>
      </AuthPage>
    </GoogleReCaptchaProvider>
  );
};

export default SignupPage;
